import { getFormProps, useForm } from '@conform-to/react'
import { parseWithZod } from '@conform-to/zod'
import { useFetcher, useFetchers } from '@remix-run/react'
import { z } from 'zod'
import { useHints } from '#app/utils/client-hints'
import { useRequestInfo } from '#app/utils/request-info'
import { type Theme } from '#app/utils/theme/theme.server'
import { type action } from '../../root'
import { cn } from '../../utils/misc'
import { Icon } from '../ui/icon'
import { SidebarNavLinkCss } from './sideBarNav'

export const ThemeFormSchema = z.object({
	theme: z.enum(['system', 'light', 'dark']),
})

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
	const hints = useHints()
	const requestInfo = useRequestInfo()
	const optimisticMode = useOptimisticThemeMode()

	if (optimisticMode) {
		return optimisticMode === 'system' ? hints.theme : optimisticMode
	}

	return requestInfo.userPrefs.theme ?? hints.theme
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
	const fetchers = useFetchers()
	const themeFetcher = fetchers.find(f => f.formAction === '/')

	if (themeFetcher && themeFetcher.formData) {
		const submission = parseWithZod(themeFetcher.formData, {
			schema: ThemeFormSchema,
		})

		if (submission.status === 'success') {
			return submission.value.theme
		}
	}
}

export default function ThemeSwitch({
	userPreference,
	isWithText = true,
	isCollapsed = false,
}: {
	userPreference?: Theme | null
	isWithText?: boolean
	isCollapsed?: boolean
}) {
	const fetcher = useFetcher<typeof action>()

	const [form] = useForm({
		id: 'theme-switch',
		lastResult:
			fetcher.data && 'result' in fetcher.data
				? fetcher.data.result
				: undefined,
	})

	const optimisticMode = useOptimisticThemeMode()
	const mode = optimisticMode ?? userPreference ?? 'system'
	const nextMode =
		mode === 'system' ? 'light' : mode === 'light' ? 'dark' : 'system'

	const modeLabel = {
		light: <Icon name="sun" />,
		dark: <Icon name="moon" />,
		system: <Icon name="laptop" />,
	}

	return (
		<fetcher.Form method="POST" {...getFormProps(form)}>
			<input type="hidden" name="theme" value={nextMode} />
			<input type="hidden" name="_action" value="theme" />
			<button type="submit" className={cn(SidebarNavLinkCss(isCollapsed))}>
				{modeLabel[mode]}
				{isWithText && (
					<span
						className={cn(
							'overflow-hidden whitespace-nowrap',
							'transition-all duration-500',
							isCollapsed
								? 'max-w-0 opacity-0'
								: 'ml-2 max-w-[200px] opacity-100',
						)}
					>
						Change Theme
					</span>
				)}
			</button>
		</fetcher.Form>
	)
}
