import { z } from 'zod'

export const accentColors = [
	'gray',
	'gold',
	'bronze',
	'brown',
	'yellow',
	'amber',
	'orange',
	'tomato',
	'red',
	'ruby',
	'crimson',
	'pink',
	'plum',
	'purple',
	'violet',
	'iris',
	'indigo',
	'blue',
	'cyan',
	'teal',
	'jade',
	'green',
	'grass',
	'lime',
	'mint',
	'sky',
] as const

export type AccentColorTheme = (typeof accentColors)[number]

export const AccentColorFormSchema = z.object({
	accentColor: z.enum(accentColors),
})
