import { useFetcher } from '@remix-run/react'
import { useState } from 'react'
import { type action } from '../../root'
import { cn } from '../../utils/misc'
import { useRequestInfo } from '../../utils/request-info'
import {
	accentColors,
	type AccentColorTheme,
} from '../../utils/theme/accentColors'
import { Button } from '../ui/radixUiTheme/Button'
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogTitle,
	DialogTrigger,
} from '../ui/radixUiTheme/Dialog'
import { TextElem } from '../ui/radixUiTheme/Typography'
import { SidebarNavLinkCss } from './sideBarNav'

export function useAccentColor() {
	const requestInfo = useRequestInfo()
	return requestInfo.userPrefs.accentColor ?? 'sky'
}

export default function AccentColorSwitch({
	userPreference,
	isWithText = true,
	isCollapsed = false,
}: {
	userPreference?: AccentColorTheme | null
	isWithText?: boolean
	isCollapsed?: boolean
}) {
	const fetcher = useFetcher<typeof action>()

	userPreference = userPreference ?? 'sky'

	return (
		<Dialog>
			<DialogTrigger>
				{!isWithText ? (
					<Button
						className={cn(
							'flex h-6 w-6 items-center justify-center rounded-full',
							`bg-[var(--${userPreference}-9)]`,
						)}
					/>
				) : (
					<div className={cn(SidebarNavLinkCss(isCollapsed))}>
						<div
							className={cn('h-[1em] w-[1em] rounded-full')}
							style={{
								// NOTE: This is a hack to get the color to work
								// because the background color is not being applied
								// correctly to the button via tailwind bg-[var(--${userPreference}-9)]
								backgroundColor: `var(--${userPreference}-9)`,
							}}
						/>
						<TextElem
							className={cn(
								'overflow-hidden whitespace-nowrap',
								'transition-all duration-500',
								isCollapsed
									? 'max-w-0 opacity-0'
									: 'ml-2 max-w-[200px] opacity-100',
							)}
						>
							Change Color Theme
						</TextElem>
					</div>
				)}
			</DialogTrigger>

			<DialogContent aria-describedby="">
				<DialogTitle>Change Accent Color</DialogTitle>
				<DialogDescription>
					Select a color to change the accent color of the platform.
				</DialogDescription>

				<div className="flex flex-wrap justify-center gap-2 p-5">
					{accentColors.map((color: AccentColorTheme) => (
						<AccentColorButton
							key={color}
							color={color}
							isSelected={color === userPreference}
							onSelect={() => {
								fetcher.submit(
									{ _action: 'accentColor', accentColor: color },
									{ method: 'POST' },
								)
							}}
						/>
					))}
				</div>

				<div className="flex grow justify-end gap-2">
					<DialogClose>
						<Button variant="soft">Close</Button>
					</DialogClose>
				</div>
			</DialogContent>
		</Dialog>
	)
}

function AccentColorButton({
	color,
	isSelected,
	onSelect,
}: {
	color: AccentColorTheme
	isSelected: boolean
	onSelect: () => void
}) {
	const [isHovered, setIsHovered] = useState(false)

	return (
		<button
			className={`flex w-14 flex-col items-center rounded-lg p-2 transition-all`}
			style={{
				backgroundColor: isSelected
					? `var(--${color}-4)`
					: isHovered
						? `var(--${color}-3)`
						: 'transparent',
			}}
			onClick={onSelect}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<div
				className="mb-2 h-8 w-8 rounded-full"
				style={{
					backgroundColor: `var(--${color}-9)`,
					transform: isHovered ? 'translateY(-2px)' : 'translateY(0)',
					transition: 'transform 0.3s ease',
				}}
			/>
			<TextElem
				color={color}
				className="text-center text-xs font-semibold hover:font-extrabold"
			>
				{color.toUpperCase()}
			</TextElem>
		</button>
	)
}
