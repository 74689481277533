import { Dialog as DialogPrimitive } from '@radix-ui/themes'

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogContent = DialogPrimitive.Content

const DialogTitle = DialogPrimitive.Title

const DialogDescription = DialogPrimitive.Description

const DialogClose = DialogPrimitive.Close

export {
	Dialog,
	DialogTrigger,
	DialogContent,
	DialogTitle,
	DialogDescription,
	DialogClose,
}
