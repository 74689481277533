import { Separator as SeparatorPrimitive } from '@radix-ui/themes'
import React from 'react'
import { cn } from '../../../utils/misc'
import { useAccentColor } from '../../sideBarNavigation/accentColorSwitch'

const Separator = React.forwardRef<
	React.ElementRef<typeof SeparatorPrimitive>,
	React.ComponentProps<typeof SeparatorPrimitive>
>(({ ...props }, ref) => {
	const accentColor = useAccentColor()

	return (
		<SeparatorPrimitive
			ref={ref}
			color={accentColor}
			className={cn('my-2', props.className)}
			size={props.size || '4'}
			{...props}
		/>
	)
})
Separator.displayName = SeparatorPrimitive.displayName

const SeparatorNormal = SeparatorPrimitive

export { Separator, SeparatorNormal }
