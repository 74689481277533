import { Link } from '@remix-run/react'

export default function Logo() {
	return (
		// NOTE: the difference between padding of the left and right sides is to
		// make the animation look more natural and prevent the link triggering when
		// slightly misclicking the collapse/expand sidebar button
		<Link to="/" className="group grid px-2 pb-2 leading-snug">
			<span className="font-bold text-[var(--accent-11)] transition group-hover:-translate-x-1">
				One
			</span>
			<span className="font-bold transition group-hover:translate-x-1">
				Stoply
			</span>
		</Link>
	)
}
