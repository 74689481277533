import { Form, NavLink, useLoaderData, useLocation } from '@remix-run/react'
import React, { useRef, useState } from 'react'
import { ClientOnly } from 'remix-utils/client-only'
import { cn, getUserImgSrc } from '#app/utils/misc'
import { useOptionalUser } from '#app/utils/user'
import { EXPERIMENTAL_FEATURES } from '../../constants'
import { type loader as RootLoader } from '../../root'
import Logo from '../headerFooter/logo'
import { Icon, type IconName } from '../ui/icon'
import { Avatar } from '../ui/radixUiTheme/Avatar'
import { Button } from '../ui/radixUiTheme/Button'
import { Separator } from '../ui/radixUiTheme/Separator'
import AccentColorSwitch from './accentColorSwitch'
import ThemeSwitch from './themeSwitch'

export function SidebarNavLinkCss(isCollapsed: boolean) {
	return cn(
		`flex py-2 items-center w-full cursor-pointer`,
		'transition-all duration-500',
		'hover:bg-[var(--accent-5)]',
		isCollapsed ? 'px-1 justify-center' : 'px-2',
	)
}
export default function MainSideBarNavigation() {
	// Since this component is rendered on every page, we can use the loader data from the root loader
	const data = useLoaderData<typeof RootLoader>()
	const user = useOptionalUser()
	const formRef = useRef<HTMLFormElement>(null)
	const location = useLocation()

	const [isCollapsed, setIsCollapsed] = useState<boolean>(() => {
		if (typeof window === 'undefined') return false

		const savedIsCollapsed = localStorage.getItem('isSideBarNavCollapsed')
		return savedIsCollapsed ? savedIsCollapsed === 'true' : false
	})

	const pathsWithoutSideBarNav = [
		'/',
		'/login',
		'/signup',
		'/forgot-password',
		'/verify',
		'/onboarding',
		'/reset-password',
		'/signup-email',
	]
	const showSideBarNav = !pathsWithoutSideBarNav.includes(location.pathname)

	const toggleCollapse = () => {
		const nextCollapsed = !isCollapsed
		setIsCollapsed(nextCollapsed)
		localStorage.setItem('isSideBarNavCollapsed', nextCollapsed.toString())
	}

	return (
		<>
			{showSideBarNav && (
				<ClientOnly fallback={<div>Loading...</div>}>
					{() => {
						return (
							<div
								className={cn(
									'relative flex h-screen border-r-2 shadow-lg',
									'transition-all duration-500',
									'bg-[var(--accent-2)]',
									isCollapsed
										? 'w-[25px] min-w-[70px]'
										: 'fixed z-50 w-screen min-w-full sm:relative sm:w-[10%] sm:min-w-[250px]',
								)}
							>
								<div className="my-2 flex grow flex-col">
									<Button
										onClick={toggleCollapse}
										size={'1'}
										className={cn(
											'absolute h-7 w-7 rounded-full',
											isCollapsed
												? '-right-4 top-3'
												: 'right-4 top-3 sm:-right-4 sm:top-3',
										)}
									>
										<Icon
											size="1"
											name={
												isCollapsed ? 'double-arrow-right' : 'double-arrow-left'
											}
										/>
									</Button>
									<Logo />

									<Separator />

									<div className="flex grow flex-col overflow-auto">
										<div className="flex flex-col">
											<SideBarNavLink
												to="map"
												iconName="globe"
												text="Buy Map"
												isCollapsed={isCollapsed}
												toggleCollapse={toggleCollapse}
											/>
											<SideBarNavLink
												to={`/users/${user?.username}/offers`}
												iconName="activity"
												text="Offers"
												isCollapsed={isCollapsed}
												toggleCollapse={toggleCollapse}
												// isVisible={!!user}
											/>
											<Separator />
										</div>

										<div className="flex flex-col">
											<SideBarNavLink
												to={`/users/${user?.username}/businessNodes`}
												iconName="locations-route"
												text="Business Nodes"
												isCollapsed={isCollapsed}
												toggleCollapse={toggleCollapse}
												// isVisible={!!user}
											/>
											<SideBarNavLink
												to={`/users/${user?.username}/customers`}
												iconName="connected-users"
												text="Customers"
												isCollapsed={isCollapsed}
												toggleCollapse={toggleCollapse}
												// isVisible={!!user}
											/>
											<SideBarNavLink
												to={`/users/${user?.username}/equipment`}
												iconName="forklift"
												text="Equipment"
												isCollapsed={isCollapsed}
												toggleCollapse={toggleCollapse}
												// isVisible={!!user}
											/>

											<Separator />
										</div>

										<div className="flex flex-1 flex-col">
											<SideBarNavLink
												to={'education'}
												iconName="archive"
												text="Education"
												isCollapsed={isCollapsed}
												toggleCollapse={toggleCollapse}
												isVisible={!!EXPERIMENTAL_FEATURES}
											/>
											<Separator />
										</div>

										<div className="flex flex-col">
											<SideBarNavLink
												to={`users/all`}
												iconName="person"
												text="All Connections"
												isCollapsed={isCollapsed}
												toggleCollapse={toggleCollapse}
												// isVisible={!!user && userHasRole(user, 'admin')}
											/>
											<SideBarNavLink
												to={`chats/all`}
												iconName="chat"
												text="All Chats"
												isCollapsed={isCollapsed}
												toggleCollapse={toggleCollapse}
												// isVisible={!!user}
											/>
											<SideBarNavLink
												to={`emails/new`}
												iconName="envelope-closed"
												text="Broadcast Emails"
												isCollapsed={isCollapsed}
												toggleCollapse={toggleCollapse}
												// isVisible={!!user}
											/>
											<SideBarNavLink
												to={`sms`}
												iconName="mobile"
												text="Broadcast SMS"
												isCollapsed={isCollapsed}
												toggleCollapse={toggleCollapse}
												// isVisible={!!user}
											/>
											<SideBarNavLink
												to={`/users/${user?.username}/reviews`}
												iconName="star"
												text="Reviews"
												isCollapsed={isCollapsed}
												toggleCollapse={toggleCollapse}
												// isVisible={!!user}
											/>

											<Separator />
										</div>

										<div className="flex flex-col">
											<SideBarNavLink
												to={`feedback`}
												iconName="bug"
												text="Submit Feedback"
												isCollapsed={isCollapsed}
												toggleCollapse={toggleCollapse}
											/>
											<SideBarNavLink
												to={`/users/${user?.username}/notifications`}
												iconName="bell"
												text="Notifications"
												isCollapsed={isCollapsed}
												toggleCollapse={toggleCollapse}
												// isVisible={!!user}
												badge={
													data.unreadNotificationCount === 0 ? undefined : (
														<>
															{data.unreadNotificationCount > 9
																? '9+'
																: data.unreadNotificationCount}
														</>
													)
												}
											/>
											<SideBarNavLink
												to={`users/${user?.username}`}
												end={true}
												specialIcon={
													<Avatar
														size={'1'}
														radius="full"
														src={getUserImgSrc(user?.image?.id)}
														fallback={`${user?.firstName?.charAt(0).toUpperCase()}${user?.lastName?.charAt(0).toUpperCase()}`}
													/>
												}
												text="Profile"
												isCollapsed={isCollapsed}
												toggleCollapse={toggleCollapse}
												isVisible={!!user}
											/>
											<SideBarNavLink
												to={`settings/profile`}
												iconName="gear"
												text="Settings"
												isCollapsed={isCollapsed}
												toggleCollapse={toggleCollapse}
												isVisible={!!EXPERIMENTAL_FEATURES && !!user}
											/>

											<Separator />
										</div>

										<div className="flex flex-col">
											<AccentColorSwitch
												userPreference={data.requestInfo.userPrefs.accentColor}
												isCollapsed={isCollapsed}
											/>

											<ThemeSwitch
												userPreference={data.requestInfo.userPrefs.theme}
												isCollapsed={isCollapsed}
											/>

											<Separator />
										</div>

										<div className="flex flex-col justify-end">
											{user ? (
												<div className={SidebarNavLinkCss(isCollapsed)}>
													<Form action="/logout" method="POST" ref={formRef}>
														<button type="submit">
															<Icon className="text-body-md" name="exit">
																{!isCollapsed && (
																	<button type="submit">Log Out</button>
																)}
															</Icon>
														</button>
													</Form>
												</div>
											) : (
												<SideBarNavLink
													to={'/login'}
													iconName="person"
													text="Log In"
													isCollapsed={isCollapsed}
													toggleCollapse={toggleCollapse}
												/>
											)}
										</div>
									</div>
								</div>
							</div>
						)
					}}
				</ClientOnly>
			)}
		</>
	)
}

function SideBarNavLink({
	to,
	end = false,
	iconName,
	specialIcon,
	text,
	isCollapsed,
	toggleCollapse,
	isVisible = true,
	badge,
}: {
	to: string
	end?: boolean
	iconName?: IconName
	specialIcon?: React.ReactNode
	text: string
	isCollapsed: boolean
	isVisible?: boolean
	badge?: React.ReactNode | undefined
	toggleCollapse: () => void
}) {
	const textClassName = cn(
		'overflow-hidden whitespace-nowrap',
		'transition-all duration-500',
		isCollapsed ? 'max-w-0 opacity-0' : 'max-w-[200px] opacity-100 ml-2',
	)

	const badgeClassName = cn(
		'flex items-center justify-center',
		'h-4 w-4 rounded-full',
		'absolute -right-2 -top-2',
		'text-xxs bg-[var(--accent-9)] text-white',
	)

	const currScreenWidth = window.innerWidth

	return (
		<>
			{isVisible ? (
				<NavLink
					prefetch="intent"
					to={to}
					end={end}
					className={({ isActive }) =>
						cn(
							SidebarNavLinkCss(isCollapsed),
							isActive && 'bg-[var(--accent-4)]',
						)
					}
					onClick={() => {
						if (!isCollapsed && currScreenWidth < 768) {
							toggleCollapse()
						}
					}}
				>
					{specialIcon ? (
						specialIcon
					) : (
						<div className={cn(badge ? 'relative' : 'flex')}>
							<Icon className="text-body-md" name={iconName as IconName}>
								{badge && <div className={badgeClassName}>{badge}</div>}
							</Icon>
						</div>
					)}

					<span className={textClassName}>{text}</span>
				</NavLink>
			) : null}
		</>
	)
}
